import type { OnChangeValue } from "react-select";

import useChangeLocation from "@/lib/hooks/useChangeLocation";
import useLocation from "@/lib/hooks/useLocation";

import { useChangeGiftCardLocation } from "@/lib/hooks/useChangeLocation/useChangeLocation";
import { useGiftCertLocation } from "@/lib/hooks/useLocation/useLocation";
import { useEffect } from "react";
import formatDropdownData from "../formatDropdownData";
import type { DropdownOption } from "./UI";
import CountryDropdownUI from "./UI";

interface CountryDropdownProps {
  onChange: (e: { target: { value: string } }) => void;
  value: string;
  setValue: (value: string) => void;
  giftCert?: boolean;
}

const CountryDropdown = ({
  onChange,
  value,
  setValue,
}: CountryDropdownProps) => {
  const { countries, location } = useLocation();
  const { changeLocation } = useChangeLocation();

  useEffect(() => {
    if (location) setValue(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  if (!countries) return null;

  const options = formatDropdownData(countries, {
    value: "country",
    label: "name",
  });

  const ChangeEvent = (e: OnChangeValue<DropdownOption, false>): void => {
    onChange({ target: { value: e?.value || "" } });
    changeLocation.mutate({
      locationId: e?.value || "",
    });
  };

  return (
    <CountryDropdownUI options={options} onChange={ChangeEvent} value={value} />
  );
};

export const GiftCardCountryDropdown = ({
  onChange,
  value,
  setValue,
}: CountryDropdownProps) => {
  const { countries, location } = useGiftCertLocation();
  const { changeLocation } = useChangeGiftCardLocation();

  useEffect(() => {
    if (location) {
      setValue(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  if (!countries) return null;

  const options = formatDropdownData(countries, {
    value: "country",
    label: "name",
  });

  const ChangeEvent = (e: OnChangeValue<DropdownOption, false>): void => {
    onChange({ target: { value: e?.value || "" } });
    changeLocation.mutate({
      locationId: e?.value || "",
    });
  };

  return (
    <CountryDropdownUI options={options} onChange={ChangeEvent} value={value} />
  );
};

export default CountryDropdown;
