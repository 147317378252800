"use client";

import Modal from "@/components/molecules/Modal";
import { LOCALES } from "@/i18n";
import CountryDropdown from "@/lib/checkout/CountryDropdown";
import { DropdownOption } from "@/lib/checkout/CountryDropdown/UI";
import Dropdown from "@/lib/checkout/Dropdown";
import useInput from "@/lib/checkout/useInput";
import useModal from "@/lib/hooks/useModal";
import { usePathname, useRouter } from "@/navigation";
import clsx from "clsx";
import { useLocale, useTranslations } from "next-intl";
import { ReactNode } from "react";
import ReactCountryFlag from "react-country-flag";
import Button from "../Button";
import styles from "./locationSelector.module.css";

export const LOCATION_SETTINGS_MODAL = "location-settings";

interface LocationListItemProps {
  children: ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

const LocationListItem = ({
  children,
  isSelected,
  onClick,
}: LocationListItemProps) => {
  const { closeModal } = useModal(LOCATION_SETTINGS_MODAL, () => {});

  return (
    <div
      className={clsx(styles.listItem, isSelected && styles.listItem__selected)}
      onClick={() => {
        onClick();
        closeModal();
      }}
    >
      {children}
    </div>
  );
};

const Country = ({ country }: { country: any }) => {
  const changeCountry = () => {
    country.select();
  };

  return (
    <LocationListItem onClick={changeCountry} isSelected={country.selected}>
      <ReactCountryFlag
        countryCode={country.country}
        svg
        alt={country?.country}
      />
      {`${country.name} (${country.currency})`}
    </LocationListItem>
  );
};

const LocationPopover = () => {
  const country = useInput("text");
  const t = useTranslations();
  const pathname = usePathname();
  const router = useRouter();
  const options: DropdownOption[] = [];
  const currentLocale = useLocale();
  const { closeModal } = useModal(LOCATION_SETTINGS_MODAL, () => {});
  LOCALES.forEach((locale) => {
    const languageNames = new Intl.DisplayNames([locale], {
      type: "language",
    });
    const countryName = languageNames.of(locale) || "";
    const upperCaseCountryName =
      countryName.charAt(0).toUpperCase() + countryName.slice(1);
    options.push({ value: locale, label: upperCaseCountryName });
  });

  if (!pathname) {
    return null;
  }

  return (
    <Modal
      modal={LOCATION_SETTINGS_MODAL}
      className={styles.modal}
      title={t("footer.location.title")}
    >
      <div className={styles.modal__content}>
        <div className={styles.section}>
          <span className={styles.label}>{t("footer.location.country")}</span>
          <CountryDropdown {...country} />
        </div>

        <div className={styles.section}>
          <span className={styles.label}>{t("footer.location.language")}</span>
          <Dropdown
            options={options}
            onChange={(val) => {
              const locale = val?.value;
              router.push(pathname, { locale: locale as "en" });
            }}
            value={currentLocale}
          />
        </div>
        <Button onClick={closeModal} color="black" full>
          Save settings
        </Button>
      </div>
    </Modal>
  );
};

export default LocationPopover;
